import { Component, OnInit, ChangeDetectorRef } from "@angular/core";

@Component({
  selector: "dedit-privacy-and-policy",
  templateUrl: "./privacy-and-policy.component.html",
  styleUrls: ["./privacy-and-policy.component.scss"],
})
export class PrivacyAndPolicyComponent implements OnInit {
  language = "en";

  constructor(private ref: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.language = localStorage.getItem("language");
    this.ref.detectChanges();
  }
}
