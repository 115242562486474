import { NgModule } from "@angular/core";

import { Routes, RouterModule } from "@angular/router";
import { DeditHomeComponent } from "./core/components/home/home.component";
import { DeditVerificationComponent } from "./core/components/verification/verification.component";
import { DeditRegistrationComponent } from "./core/components/registration/registration.component";
import { DeditBusinessComponent } from "./core/components/business/business.component";
import { DeditPlansComponent } from "./core/components/plans/plans.component";
import { DeditSupportComponent } from "./core/components/support/support.component";
import { AuthGuard } from "./auth/services/auth-guard.service";
import { DeditFAQComponent } from "./core/components/faq/faq.component";
import { DeditToSComponent } from "./core/components/tos/tos.component";
import { PrivacyAndPolicyComponent } from "src/app/core/components/privacy-and-policy/privacy-and-policy.component";

const routes: Routes = [
  { path: "", component: DeditHomeComponent },
  { path: "home", canActivate: [AuthGuard], component: DeditHomeComponent },
  {
    path: "user",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./user/user.module").then((m) => m.DeditUserModule),
  },
  {
    path: "auth",
    loadChildren: () =>
      import("./auth/auth.module").then((m) => m.DeditAuthModule),
  },
  { path: "business", component: DeditBusinessComponent },
  { path: "plans", component: DeditPlansComponent },
  { path: "support", component: DeditSupportComponent },
 /*  { path: "faq", component: DeditFAQComponent }, */
  { path: "registration", component: DeditRegistrationComponent },
  { path: "verification/:hash", component: DeditVerificationComponent },
  { path: "tos", component: DeditToSComponent },
  { path: "privacy", component: PrivacyAndPolicyComponent },
  { path: "**", redirectTo: "" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
