import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {NbAuthService} from '@nebular/auth';
import {User} from '../models/User';
import {Credentials} from '../../auth/models/Credentials';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private userApi = environment.api + '/user/';

  constructor(private http: HttpClient, private authService: NbAuthService) {
  }

  public verifyemail(email) {
    return this.http.post<any>(
      this.userApi + 'verifyemail',
      email,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      });
  }
  public verify(wallet, jwt) {
    return this.http.post<any>(
      this.userApi + 'verify',
      wallet,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer '.concat(jwt),
        })
      });
  }

  user(): Observable<User> {
    return this.authService.getToken().pipe(switchMap(
      token => this.http.get<User>(
        this.userApi,
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: 'Bearer '.concat(token.getValue())
          })
        })
    ));
  }

  /*
    getTransactions(): Promise<{any}> {
      return this.http.get(`${Api.Url}transaction`).toPromise()
        .then(r => {
          return r;
        }).catch(error => {
          return Promise.reject(error);
        });
    }
    */

  update(user: User): Promise<any> {
    return this.authService.getToken().pipe(switchMap(
      token => this.http.patch(
        this.userApi,
        user,
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: 'Bearer '.concat(token.getValue())
          })
        })
    )).toPromise()
      .then(r => {
        return r;
      }).catch(error => {
        console.log(error);
        return Promise.reject(error);
      });
  }

  public resetPassword(creds: any, jwt: string) {
    return this.http.post<any>(
      this.userApi + 'reset-password',
      creds,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer '.concat(jwt),
        })
      });
  }
}
