// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  wlv: window.location.hostname.split(".")[0],
  secretUUID: "68bedd6f-4db9-4b35-be41-8dbfe1db3c59",
  api: "api/v2",
  paypal:
    "AXRS5zflMY8twdPymMfrpyHBRTf778I35Jk4pBZXOBUW_UWkohYaBQsqcUkNtx9yZ69NwqsnFQelBu8s",
  stripe: "pk_test_XSWDgij84CaMB27ctxpqm95h00lPqZ1n45",
  plans: {
    ethereum: {
      starter: "plan_GiR1zJDXyRuRTB",
      medium: "plan_HImQkp0Fh6HZ60",
      pro: "plan_GiR2owyxjnZHlS",
    },
    algorand: {
      // prodotti ptest
      free: "price_1MVXkfGkNaUEmNJUTbH3O466",
      notime: "price_1MfsYjGkNaUEmNJUoLL62p9u",
      base: "price_1MgBihGkNaUEmNJURek1AO2L",
      baseSPID: "price_1MgBihGkNaUEmNJUW5bkzn9X",
      pro: "price_1MgBihGkNaUEmNJUMhva25kU",
      proSPID: "price_1MgBihGkNaUEmNJUMQfoOQRw",
      base_mese: "price_1MgBihGkNaUEmNJURFn5LJs6",
      baseSPID_mese: "price_1MgBihGkNaUEmNJUWxpLdiIh",
      pro_mese: "price_1MgBihGkNaUEmNJUo9EOKzYn",
      proSPID_mese: "price_1MgBihGkNaUEmNJUEP4uSSGg",
    },
  },
  keycloak: {
    config: {
      url: "https://account.dedit.io/auth",
      realm: "Dedit-account",
      clientId: "dedit-account",
      redirectUri: "http://localhost:4200",
    },
    initOptions: {
      onLoad: "check-sso",
      silentCheckSsoRedirectUri:
        window.location.origin + "/assets/silent-check-sso.html",
    },
  },
  ethereumEnv: "mainnet",
  ethereumProvider: "1501cd1504d04a799ac3773b1db5f6ec",

  algorandEnv: "https://mainnet-idx.algonode.cloud",
  port: "",
  cacheBusterHash: Math.random().toString(36).substring(7),
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
