import CryptoAES from "crypto-js/aes";
import crypto from "crypto-js";
import { environment } from "../src/environments/environment";

const secret = environment.secretUUID;

const encryptPassword = (password: string): string => {
  try {
    if (!password) throw new Error("Password is required");
    return CryptoAES.encrypt(password, secret).toString();
  } catch (error) {
    console.log(error);
  }
};

const decryptPassword = (encryptPassword: string): string => {
  try {
    if (!encryptPassword) throw new Error("EncryptPassword is required");
    return CryptoAES.decrypt(encryptPassword.toString(), secret).toString(
      crypto.enc.Utf8
    );
  } catch (error) {
    console.log(error);
  }
};

export { encryptPassword, decryptPassword };
