import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { UploadService } from "./upload.service";
import { Registration } from "../models/Registration";
import { RegistrationService } from "./registration.service";
import { Router } from "@angular/router";
import { UserSignature } from "../../user/models/UserSignature";
import { switchMap } from "rxjs/operators";
import { NbAuthService } from "@nebular/auth";
import { Observable } from "rxjs";
import { Subscription } from "../../user/models/Subscription";

@Injectable({
  providedIn: "root",
})
export class VerificationService {
  private verificationApi = environment.api + "/registration/";
  private userApi = environment.api + "/user/";

  // tslint:disable-next-line:max-line-length
  constructor(
    private http: HttpClient,
    protected router: Router,
    private uploadService: UploadService,
    private registrationService: RegistrationService,
    private authService: NbAuthService
  ) {}

  verify(hash): Promise<any> {
    return this.http.get<any>(this.verificationApi + hash).toPromise();
  }

  verifyWithAuth(hash): Observable<Registration> {
    return this.authService.getToken().pipe(
      switchMap((token) =>
        this.http.get<Registration>(this.verificationApi + hash, {
          headers: new HttpHeaders({
            Authorization: "Bearer ".concat(token.getValue()),
          }),
        })
      )
    );
  }

  getUser(userId: Number) {
    return this.authService.getToken().pipe(
      switchMap((token) =>
        this.http.get<any[]>(this.userApi + userId, {
          headers: new HttpHeaders({
            Authorization: "Bearer ".concat(token.getValue()),
          }),
        })
      )
    );
  }

  handleRegistrationIntent(r: Registration) {
    r.file = this.uploadService.file().getValue();
    this.registrationService.registration().next(r);
    this.router.navigate(["/registration"]);
  }

  sendSignRequest(hash: string, email: string, jwt) {
    const params = "?hash=" + hash + "&email=" + email;
    return this.http
      .get<any>(this.userApi + "send-sign-request" + params, {
        headers: new HttpHeaders({
          Authorization: "Bearer ".concat(jwt),
        }),
      })
      .toPromise();
  }

  sendCertificate(formData: FormData, jwt) {
    // tslint:disable-next-line:max-line-length
    return this.http
      .post<any>(this.userApi + "send-certificate", formData, {
        headers: new HttpHeaders({
          Authorization: "Bearer ".concat(jwt),
        }),
      })
      .toPromise();
  }

  sendEmail(to, subject, body, jwt) {
    // tslint:disable-next-line:max-line-length
    return this.http.post<any>(
      this.userApi +
        "send-email" +
        "?to=" +
        to +
        "&subject=" +
        subject +
        "&body=" +
        body,
      {
        headers: new HttpHeaders({
          Authorization: "Bearer ".concat(jwt),
        }),
      }
    );
  }

  sign(signature: UserSignature, jwt) {
    return this.http.post<any>(this.userApi + "signatures", signature, {
      headers: new HttpHeaders({
        Authorization: "Bearer ".concat(jwt),
      }),
    });
  }

  retrysign(signature: UserSignature, jwt) {
    return this.http.post<any>(this.userApi + "retrysignatures", signature, {
      headers: new HttpHeaders({
        Authorization: "Bearer ".concat(jwt),
      }),
    });
  }
  getSignatures(hash: string) {
    return this.http.get<any>(this.userApi + "signatures/" + hash);
  }
}
