import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { BehaviorSubject } from "rxjs";
import { Registration } from "../models/Registration";
import { Router } from "@angular/router";
import { NbAuthService } from "@nebular/auth";
import { Wallet } from "ethers";
import { FileService } from "./file.service";
import { Metadata } from "../models/Metadata";
import { switchMap } from "rxjs/operators";
import { Subscription } from "../../user/models/Subscription";
import { sha256 } from "js-sha256";
import * as algosdk from "algosdk";
import * as ed from "noble-ed25519";
import jwtDecode from "jwt-decode";

@Injectable({
  providedIn: "root",
})
export class RegistrationService {
  private registrationApi = environment.api + "/registration/";
  private userApi = environment.api + "/user";
  private readonly registrationBehaviorSubject: BehaviorSubject<Registration>;

  // tslint:disable-next-line:max-line-length
  constructor(
    protected http: HttpClient,
    protected router: Router,
    private authService: NbAuthService,
    private fileService: FileService
  ) {
    this.registrationBehaviorSubject = new BehaviorSubject<Registration>(null);
  }

  registration() {
    return this.registrationBehaviorSubject;
  }

  registrationPrice() {
    return this.http.get<any>(this.registrationApi + "price");
  }

  registrationCount() {
    return this.authService.getToken().pipe(
      switchMap((token) =>
        this.http.patch<any>(this.registrationApi + "count", null, {
          headers: new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer ".concat(token.getValue()),
          }),
        })
      )
    );
  }

  registrationCountFree() {
    return this.authService.getToken().pipe(
      switchMap((token) =>
        this.http.patch<any>(this.registrationApi + "countFree", null, {
          headers: new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer ".concat(token.getValue()),
          }),
        })
      )
    );
  }

  async requestRegistration(
    registration: Registration,
    subscription = false,
    verifica = true
  ): Promise<string> {
    const token = await this.authService.getToken().toPromise();
    if (token && token.isValid()) {
      return this.http
        .post<any>(
          this.registrationApi + "?subscription=" + subscription,
          registration,
          {
            headers: new HttpHeaders({
              "Content-Type": "application/json",
              Authorization: "Bearer ".concat(token.getValue()),
            }),
          }
        )
        .toPromise()
        .then(
          (order) => order.order_id,
          (err) => err.message
        );
    } else {
      return this.http
        .post<any>(this.registrationApi, registration)
        .toPromise()
        .then(
          (order) => order.order_id,
          (err) => err.message
        );
    }
  }

  async completeRegistration(registration: Registration, order) {
    let params = registration.hash + "/completion";
    if (registration.signer && registration.signature) {
      params =
        params +
        "?signer=" +
        registration.signer +
        "&signature=" +
        registration.signature +
        "&auth=" +
        registration.auth;
    }
    return this.http
      .post<any>(this.registrationApi + params, order)
      .toPromise();
  }

  async forfaitRegistration(
    registration: Registration,
    subscription: Subscription
  ) {
    let params = registration.hash + "/forfait";
    if (registration.signer && registration.signature) {
      let sessionID = "";
      if (registration.auth === "SPID") {
        sessionID = jwtDecode(localStorage.getItem("accessToken"))["sid"];
      }
      params =
        params +
        "?signer=" +
        registration.signer +
        "&signature=" +
        registration.signature +
        "&auth=" +
        registration.auth +
        "&sessionID=" +
        sessionID;
    }

    return this.http
      .post<any>(this.registrationApi + params, subscription)
      .toPromise();
  }

  async forfaitNORegistration(
    registration: Registration,
    subscription: Subscription
  ) {
    let params = registration.hash + "/forfaitNO";
    if (registration.signer && registration.signature) {
      params =
        params +
        "?signer=" +
        registration.signer +
        "&signature=" +
        registration.signature +
        "&auth=" +
        registration.auth;
    }
    return this.http
      .post<any>(this.registrationApi + params, subscription)
      .toPromise();
  }

  async freeRegistration(registration: Registration) {
    let params = registration.hash + "/free";
    if (registration.signer && registration.signature) {
      params =
        params +
        "?signer=" +
        registration.signer +
        "&signature=" +
        registration.signature +
        "&auth=" +
        registration.auth;
    }
    return this.authService
      .getToken()
      .pipe(
        switchMap((token) =>
          this.http.post<any>(this.registrationApi + params, null, {
            headers: new HttpHeaders({
              "Content-Type": "application/json",
              Authorization: "Bearer ".concat(token.getValue()),
            }),
          })
        )
      )
      .toPromise();
  }

  async signRegistration(
    registration: Registration,
    wallet: Wallet | string,
    sk?: string
  ) {
    const { hash } = registration;
    // @ts-expect-error
    const skArray = Uint8Array.from(sk.split(","));
    const signedPayload = await ed.sign(hash, skArray.slice(0, 32));

    registration.signer = wallet as string;
    registration.signature = signedPayload;
    return [wallet, signedPayload];
  }

  async verifyRegistration(
    note: any,
    hash: string,
    blockchainType: string,
    wallet: string
  ) {
    if (blockchainType === "ETHEREUM" && !(typeof wallet === "string")) {
    } else {
      try {
        const noteAsJson = JSON.parse(note);
        if (!noteAsJson) return false;
        const encryptedHash = noteAsJson.signature;
        const { publicKey } = algosdk.decodeAddress(wallet);

        return await ed.verify(encryptedHash, hash, publicKey);
      } catch {
        return false;
      }
    }
  }

  async custody(file: File) {
    const token = await this.authService.getToken().toPromise();
    const metadata: Metadata = {
      hash: this.registration().getValue().hash,
      name: file.name,
      type: file.type,
      size: file.size,
      lastModified: file.lastModified,
      userId: token.getPayload().userId,
      walletName:
        "null--" + new Date().toISOString() + "--" + sha256(file.name),
    };
    const encryptedBlob = await this.fileService.encrypt(file, "null");
    const formData = new FormData();
    formData.append("file", encryptedBlob, metadata.hash + ".encrypted");
    await this.fileService
      .uploadMetadata(metadata, token.getValue())
      .toPromise()
      .catch((err) => {
        throw new Error(err);
      });
    await this.fileService
      .uploadContent(metadata, formData, token.getValue())
      .toPromise()
      .catch((err) => {
        throw new Error(err);
      });
  }

  async verifyPassword(password) {
    const token = await this.authService.getToken().toPromise();
    if (token && token.isValid()) {
      return this.http
        .post<any>(
          this.userApi + "/check-password",
          { password },
          {
            headers: new HttpHeaders({
              "Content-Type": "application/json",
              Authorization: "Bearer ".concat(token.getValue()),
            }),
          }
        )
        .toPromise();
    }
  }
}
