import { BrowserModule } from "@angular/platform-browser";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RecaptchaModule, RecaptchaFormsModule } from "ng-recaptcha";

import {
  NbThemeModule,
  NbLayoutModule,
  NbActionsModule,
  NbUserModule,
  NbCardModule,
  NbButtonModule,
  NbInputModule,
  NbChatModule,
  NbContextMenuModule,
  NbMenuModule,
  NbTooltipModule,
  NbStepperModule,
  NbCheckboxModule,
  NbRadioModule,
  NbSelectModule,
  NbDialogModule,
  NbToastrModule,
  NbSpinnerModule,
  NbAlertModule,
  NbProgressBarModule,
  NbToggleModule,
  NbAccordionModule,
  NbSidebarModule,
  NbIconModule,
  NbWindowModule,
  NbFormFieldModule,
  NbTabsetModule,
} from "@nebular/theme";
import {
  NbAuthJWTToken,
  NbAuthModule,
  NbPasswordAuthStrategy,
} from "@nebular/auth";
import { NbEvaIconsModule } from "@nebular/eva-icons";
import { AppRoutingModule } from "./app-routing.module";
// import { ParticlesModule } from 'angular-particle';
import { DeditHomeComponent } from "./core/components/home/home.component";
// @ts-ignore
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { DeditVerificationComponent } from "./core/components/verification/verification.component";
import { DeditRegistrationComponent } from "./core/components/registration/registration.component";
import { FormsModule } from "@angular/forms";
// import {TagInputModule} from 'ngx-chips';
import { DeditBusinessComponent } from "./core/components/business/business.component";
import { DeditPlansComponent } from "./core/components/plans/plans.component";
import { DeditSupportComponent } from "./core/components/support/support.component";
import { AuthGuard } from "./auth/services/auth-guard.service";
import { NgxPayPalModule } from "ngx-paypal";
import { environment } from "../environments/environment";
import { MatCardModule } from "@angular/material/card";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSnackBarModule } from "@angular/material/snack-bar";

import { DeditFAQComponent } from "./core/components/faq/faq.component";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { DeditToSComponent } from "./core/components/tos/tos.component";
import { NgxFilesizeModule } from "ngx-filesize";
// import {NgxStripeModule} from 'ngx-stripe';

import { WebcamModule } from "ngx-webcam";
import { KeycloakAngularModule, KeycloakService } from "keycloak-angular";
import { PrivacyAndPolicyComponent } from "./core/components/privacy-and-policy/privacy-and-policy.component";
import { MatPaginatorIntl } from "@angular/material/paginator";
import { CustomPaginatorService } from "src/app/shared/custom-paginator.service";
import { Base64DecodePipe } from './base64-decode.pipe';

const strategies: any = [
  NbPasswordAuthStrategy.setup({
    name: "email",
    token: {
      class: NbAuthJWTToken,
      key: "token", // this parameter tells where to look for the token
    },
    baseEndpoint: environment.api,
    login: {
      endpoint: "/auth/login",
      method: "post",
    },
    logout: {
      endpoint: "/auth/logout",
      method: "post",
    },
    register: {
      endpoint: "/user/register",
      method: "post",
    },
    requestPass: {
      endpoint: "/user/request-password",
      method: "post",
    },
    resetPass: {
      endpoint: "/user/reset-password",
      method: "post",
      requireValidToken: true,
    },
  }),
];

const forms: any = {
  login: {
    redirect: {
      success: "/user", // welcome page path
      failure: null, // stay on the same page
    },
    redirectDelay: 1500, // delay before redirect after a successful login, while success message is shown to the user
    strategy: "email", // strategy id key.
    rememberMe: true, // whether to show or not the `rememberMe` checkbox
    showMessages: {
      // show/not show success/error messages
      success: true,
      error: true,
    },
    // socialLinks: socialLinks, // social links at the bottom of a page
  },
  loginEm: {
    redirect: {
      success: "/user", // welcome page path
      failure: null, // stay on the same page
    },
    redirectDelay: 1500, // delay before redirect after a successful login, while success message is shown to the user
    strategy: "email", // strategy id key.
    rememberMe: true, // whether to show or not the `rememberMe` checkbox
    showMessages: {
      // show/not show success/error messages
      success: true,
      error: true,
    },
    // socialLinks: socialLinks, // social links at the bottom of a page
  },
  register: {
    redirectDelay: 15000,
    redirect: {
      success: null, // stay on the same page
      failure: null, // stay on the same page
    },
    strategy: "email",
    showMessages: {
      success: true,
      error: true,
    },
    terms: true,
    privacy: true,
    // socialLinks: socialLinks,
  },
  requestPassword: {
    redirectDelay: 1500,
    redirect: {
      success: null, // stay on the same page
      failure: null, // stay on the same page
    },
    strategy: "email",
    showMessages: {
      success: true,
      error: true,
    },
    // socialLinks: socialLinks,
  },
  resetPassword: {
    redirectDelay: 1500,
    redirect: {
      success: null, // stay on the same page
      failure: null, // stay on the same page
    },
    strategy: "email",
    showMessages: {
      success: true,
      error: true,
    },
    // socialLinks: socialLinks,
  },
  logout: {
    redirectDelay: 1500,
    strategy: "email",
  },
  validation: {
    usertType: {
      required: true,
    },
    password: {
      required: true,
      minLength: 5,
      maxLength: 50,
    },
    email: {
      required: true,
    },
    firstName: {
      required: true,
      minLength: 2,
      maxLength: 50,
    },
    lastName: {
      required: false,
      minLength: 2,
      maxLength: 50,
    },
    address: {
      required: false,
      minLength: 5,
      maxLength: 50,
    },
    extendedAddress: {
      required: false,
      minLength: 2,
      maxLength: 50,
    },
    place: {
      required: false,
      minLength: 2,
      maxLength: 50,
    },
    postalCode: {
      required: false,
      minLength: 5,
      maxLength: 50,
    },
    region: {
      required: false,
      minLength: 2,
      maxLength: 50,
    },
    country: {
      required: false,
      minLength: 4,
      maxLength: 50,
    },
    sdi: {
      required: false,
      minLength: 4,
      maxLength: 50,
    },
    pec: {
      required: false,
      minLength: 4,
      maxLength: 50,
    },
  },
};

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(
    http,
    "/assets/i18n/",
    ".json?cacheBuster=" + environment.cacheBusterHash
  );
}

@NgModule({
  declarations: [
    AppComponent,
    DeditHomeComponent,
    DeditVerificationComponent,
    DeditRegistrationComponent,
    DeditBusinessComponent,
    DeditPlansComponent,
    DeditSupportComponent,
    DeditFAQComponent,
    DeditToSComponent,
    PrivacyAndPolicyComponent,
    Base64DecodePipe,
  ],
  imports: [
    NbSidebarModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    BrowserModule,
    BrowserAnimationsModule,
    NbThemeModule.forRoot({ name: "cosmic" }),
    NbLayoutModule,
    NbEvaIconsModule,
    AppRoutingModule,
    HttpClientModule,
    NbActionsModule,
    NbUserModule,
    NgxFilesizeModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    // ParticlesModule,
    NbCardModule,
    NbButtonModule,
    NbInputModule,
    NbChatModule,
    NbContextMenuModule,
    NbMenuModule.forRoot(),
    NbTooltipModule,
    NbAuthModule.forRoot({
      strategies,
      forms,
    }),
    NbStepperModule,
    NbCheckboxModule,
    NbRadioModule,
    FormsModule,
    // TagInputModule,
    // NgxStripeModule.forRoot(environment.stripe),
    NbSelectModule,
    NbDialogModule.forRoot(),
    NbToastrModule.forRoot(),
    NbSpinnerModule,
    NgxPayPalModule,
    NbAlertModule,
    NbProgressBarModule,
    NbToggleModule,
    MatCardModule,
    MatProgressSpinnerModule,
    NbAccordionModule,
    NbSidebarModule,
    NbIconModule,
    MatSnackBarModule,
    WebcamModule,
    NbWindowModule.forRoot(),
    KeycloakAngularModule,
    NbFormFieldModule,
    NbTabsetModule,
  ],
  providers: [
    AuthGuard,
    { provide: MatPaginatorIntl, useClass: CustomPaginatorService },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
