import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { NbAuthService } from "@nebular/auth";
import { switchMap } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class OtpService {
  private userApi = environment.api + "/user";

  constructor(protected http: HttpClient, private authService: NbAuthService) {}

  async sendOtpCode() {
    return this.authService
      .getToken()
      .pipe(
        switchMap((token) =>
          this.http.post<any>(
            this.userApi + "/otp/send",
            { referenceID: `DEDIT-OTP-${token.getName()}` },
            {
              headers: new HttpHeaders({
                "Content-Type": "application/json",
                Authorization: "Bearer ".concat(token.getValue()),
              }),
            }
          )
        )
      )
      .toPromise();
  }

  async verifyOtpCode(otpCode: string) {
    return this.authService
      .getToken()
      .pipe(
        switchMap((token) =>
          this.http.post<any>(
            this.userApi + "/otp/verify",
            { referenceID: `DEDIT-OTP-${token.getName()}`, otpCode },
            {
              headers: new HttpHeaders({
                "Content-Type": "application/json",
                Authorization: "Bearer ".concat(token.getValue()),
              }),
            }
          )
        )
      )
      .toPromise();
  }
}
