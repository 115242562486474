import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "base64Decode",
})
export class Base64DecodePipe implements PipeTransform {
  transform(value: string): string {
    return this.decodeBase64(value);
  }

  private decodeBase64(input: string): string {
    const binaryString = window.atob(input);
    const bytes = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return new TextDecoder().decode(bytes);
  }
}
